const RandomCatBackground = () => { return `cat${Math.floor(Math.random() * 4) + 1}` }

const RandomNumber = (lenght = 4) => {
    let card = '';
    do {
        card += Math.floor(Math.random() * 10)
    } while (card.length < lenght)

    return card
}

const newExpDate = () => {
    let month = Math.floor(Math.random() * 12) + 1;

    `${month}`.length < 2 ? month = '0' + month : month = `${month}`;

    const year = `${new Date().getYear() - 98}`

    return month + '/' + year
}

export const GenerateCard = ({ firstName, lastName }) => ({
    number: `${RandomNumber()} ${RandomNumber()} ${RandomNumber()} ${RandomNumber()}`,
    firstName: firstName,
    lastName: lastName,
    expDate: newExpDate(),
    balance: 0,
    cvv: RandomNumber(3),
    background: RandomCatBackground(),
    history: [],
})