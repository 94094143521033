export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
export const ADD_CARD = 'ADD_CARD';
export const DELETE_CARD = 'DELETE_CARD';
export const ADD_MONEY = 'ADD_MONEY';
export const DONATE_MONEY = 'DONATE_MONEY';
export const SHOW_HISTORY = 'SHOW_HISTORY';
export const SHOW_ADD_CARD = 'SHOW_ADD_CARD';
export const SHOW_DELETE_CARD = 'SHOW_DELETE_CARD';
export const SHOW_ADD_MONEY = 'SHOW_ADD_MONEY';
export const SAVE_FORM_DATA = 'SAVE_FORM_DATA';
export const CARD_COPIED = 'CARD_COPIED';
export const CLOSE_FORMS = 'CLOSE_FORMS';