import './styles.scss';
import { connect } from 'react-redux';
import { showAddCardForm, } from 'store/actions';


const AddCard = ({ showAddCardForm, }) => (
    <div className="card-item add-new-card">
        <button className="add-new-card-button"
            onClick={showAddCardForm}></button>
    </div>
)


const mapDispatchToProps = {
    showAddCardForm,
}

export default connect(null, mapDispatchToProps)(AddCard);