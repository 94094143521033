import './styles.scss';
import Logo from 'assets/vectors/logo.svg'

const CompanyLogo = () => {

    return (
        <>
            <hr />
            <img src={Logo} alt="Hillel IT School" />
        </>
    )
}

export default CompanyLogo;