import './styles.scss';

const DonateText = () => {

    return (<div className='donate-text-outlet flex-element'>
        <h2 className='donate-title'>😽</h2>
        <h2 className='donate-title'>Help our kittens to be happier!</h2>
        <p className="donate-text">Please fill  in the information for the generated card.</p>
        <p className="donate-text">Every hryvnia counts.</p>
    </div>)

}

export default DonateText;