import * as Yup from 'yup';

export const donateValidate = Yup.object({
    fullName: Yup.string()
        .required('First and last name are required')
        .matches(/^[a-z]{2,20} [a-z]{2,20}$/i, 'First and last name are required'),
    number: Yup.string()
        .required('Card number is required')
        .matches(/^\d{16}$/, 'Should be 16 digits length'),
    expDate: Yup.string()
        .required('Date of expire is required')
        .matches(/^\d{2}\/\d{2}$/, 'Date should be in format MM/YY'),
    cvv: Yup.string()
        .required('CVV is required')
        .matches(/^\d{3}$/, 'CVV should contain three digits'),
    amount: Yup.number()
        .required('Amount required')
        .positive('Amount must be more than 0')
        .integer('Amount should be integer')
        .max(50000,'Transactions more than 50000 are not allowed'),
});

export const newCardValidate = Yup.object({
    firstName: Yup.string()
        .required('First name required')
        .matches(/^[a-z]|\s*$/i, 'Only a-z symbols')
        .min(2, 'Last name should be more than 2 symbols')
        .max(20, 'Last name should be less than 20 symbols'),
    lastName: Yup.string()
        .required('Last name required')
        .matches(/^[a-z]*$/i, 'Only a-z symbols')
        .min(2, 'Last name should be more than 2 symbols')
        .max(20, 'Last name should be less than 20 symbols'),
});

export const addMoneyValidate = (balance) => Yup.object({
    amount: Yup.number()
        .required('Amount required')
        .positive('Amount must be more than 0')
        .integer('Amount should be integer')
        .max(100000 - balance, 'Maximum card balance is 100000'),
});
