export const addCard = (payload) => ({
    type: 'ADD_CARD',
    payload
});

export const deleteCard = (payload) => ({
    type: 'DELETE_CARD',
    payload
});

export const addMoney = (payload) => ({
    type: 'ADD_MONEY',
    payload
});

export const donateMoney = (payload) => ({
    type: 'DONATE_MONEY',
    payload
});

export const setActiveTab = (payload) => ({
    type: 'SET_ACTIVE_TAB',
    payload
})

export const showHistory = (payload) => ({
    type: 'SHOW_HISTORY',
    payload
});

export const showAddCardForm = () => ({
    type: 'SHOW_ADD_CARD',
});

export const showAddMoneyForm = (payload) => ({
    type: 'SHOW_ADD_MONEY',
    payload
});

export const showDeleteCardForm = (payload) => ({
    type: 'SHOW_DELETE_CARD',
    payload
});

export const copyToClipboard = (payload) => ({
    type: 'CARD_COPIED',
    payload
});

export const saveFormData = (payload) => ({
    type: 'SAVE_FORM_DATA',
    payload
});

export const closeForms = () => ({
    type: 'CLOSE_FORMS',
});