import './styles.scss';

import AppTitle from './AppTitle';
import { connect } from 'react-redux';

import Account from './Tabs/Account'
import Donate from './Tabs/Donate'

import { closeForms, setActiveTab } from 'store/actions';

const MainApp = ({ activeTab, setActiveTab, closeForms }) => {

    const switchTab = (e) => {
        setActiveTab(e.target.id)
        closeForms()
    }

    return (
        <div className='app-main-frame'>
            <div className="app-head flex-container">
                <AppTitle
                    text={'😻 Help for kittens'}
                />
                <div className="tabs-switcher">
                    <button
                        id="account"
                        className={activeTab === "account" ? "tab-button active" : "tab-button"}
                        onClick={switchTab}
                    >
                        Account
                    </button>
                    <button
                        id="donate"
                        className={activeTab === "donate" ? "tab-button active" : "tab-button"}
                        onClick={switchTab}
                    >
                        Donate
                    </button>
                </div>
            </div>
            <div className="active-tab">
                {activeTab === 'donate' ?
                    <Donate /> :
                    < Account />}
            </div>
        </div>
    )
}

const mapStateToPrors = state => ({
    activeTab: state.activeTab
});

const mapDispatchToProps = {
    setActiveTab,
    closeForms
}

export default connect(mapStateToPrors, mapDispatchToProps)(MainApp);