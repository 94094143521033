import './styles.scss';

import MainApp from './MainApp'

import Instruction from './Instruction';


const Kitties = () => {

    return (
        <div className='kitties-app-root flex-container'>
            <MainApp />
            <Instruction />
        </div>
    )
}

export default Kitties;