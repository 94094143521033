import './styles.scss';
import { connect } from 'react-redux';
import { showAddMoneyForm, showDeleteCardForm, showHistory, copyToClipboard, closeForms } from 'store/actions';

const Card = ({ number, firstName, lastName, expDate, balance, cvv, showAddMoneyForm, showDeleteCardForm, background, showHistory, copyToClipboard, closeForms }) => (
    <div className={`card-item bank-card background-${background}`}>
        <div className="info-top">
            <div className="info-top">
                <div className="card-balance" onClick={() => showHistory(number)}>{balance + "₴"}</div>
                <button className="add-money card-button" onClick={() => showAddMoneyForm(number)}></button>
            </div>
            <button className="delete-card card-button" onClick={() => {showDeleteCardForm(number)}}></button>
        </div>
        <div className="info-bottom">
            <div className="cvv-container">
                <div className="text-pre small-text">
                    <span className='cvv'>CVV</span>
                    <span className='cvc'>CVC</span>
                </div>
                <div className="cvv-num medium-text">
                    {cvv}
                </div>
            </div>

            <div className="card-number big-text" onClick={() => { copyToClipboard(number); setTimeout(() => closeForms(), 700); }}>
                {number}
            </div>

            <div className="exp-date-container">
                <div className="text-pre small-text">
                    <span>VALID</span>
                    <span>THROUGH</span>
                </div>
                <div className="exp-date medium-text">
                    {expDate}
                </div>
            </div>

            <div className="name medium-text">
                {firstName} {lastName}
            </div>
        </div>
    </div>
)

const mapState = state => ({
    cards: state.cards
});

const mapFunc = {
    showAddMoneyForm,
    showDeleteCardForm,
    showHistory,
    copyToClipboard,
    closeForms
}

export default connect(mapState, mapFunc)(Card);