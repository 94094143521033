import './styles.scss';
import Logo from 'components/shared/Logo';

const Instruction = () => {


    return (
        <div
            className='app-instruction'
        >
            <h2 className="requirements-title">Requirements for testing</h2>
            <div className='scrollable'>

                <p className="requirements-text">
                    The application is designed to help kittens. Create your account with Name, Surname 2 - 20 English chars.
                </p>
                <p className="requirements-text">
                    Add a card. Possibly manipulate it, deposit money, or delete the card.
                </p>
                <p className="requirements-text">
                    Card restrictions:
                    <ul>
                        <li>Only 5 cards</li>
                        <li>Max amount on one card is 100000</li>
                    </ul>
                </p>
                <p className="requirements-text">
                    The next step is to transfer money to kittens.  To donate money use the added card.
                </p>
            </div>
            <Logo />
        </div>
    )
}

export default Instruction;