import './styles.scss';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { connect } from 'react-redux';

import { newCardValidate } from '../../../shared/myValidation'

import { addCard, closeForms } from 'store/actions';

import MyErrorMessage from 'components/shared/MyErrorMessage';

const NewCardForm = ({ addCard, closeForms }) => {
    const formValidation = newCardValidate;
    const initualValues = {
        firstName: '',
        lastName: '',
    }

    const submitHandler = async (values) => {
        addCard(values);
        closeForms();
    }

    return (<div className='custom-form-outlet'>
        <div className='shade-background'/>
        <div className="my-form">
            <div className="form-heading">
                <h4 className='form-title'>Generating a new card</h4>
                <button className="close-button" onClick={closeForms}></button>
            </div>

            <Formik
                initialValues={initualValues}
                onSubmit={submitHandler}
                validationSchema={formValidation}
            >
                {form => (<Form className='add-new-card-form'>
                    <div className='controls-container'>
                        <label htmlFor="firstName">First name</label>
                        <Field
                            as="input"
                            className="controls-input"
                            id="firstName"
                            name="firstName"
                            placeholder="Mark"
                        />
                        <ErrorMessage name="firstName" component={err => <MyErrorMessage text={err.children} />} />
                    </div>

                    <div className='controls-container'>
                        <label htmlFor="lastName">Last name</label>
                        <Field
                            as="input"
                            className="controls-input"
                            id="lastName"
                            name="lastName"
                            placeholder="Zuckerberg"
                        />
                        <ErrorMessage name="lastName" component={err => <MyErrorMessage text={err.children} />} />
                    </div>

                    <button type="submit" className='controls-button submit-button' disabled={!form.isValid || !form.dirty}>Create a virtual card</button>
                </Form>
                )}
            </Formik>
        </div>

    </div>)

}

const mapDispatchToProps = {
    addCard,
    closeForms
}

export default connect(null, mapDispatchToProps)(NewCardForm);