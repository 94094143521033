import { ADD_CARD, DELETE_CARD, ADD_MONEY, DONATE_MONEY, SET_ACTIVE_TAB, SHOW_HISTORY, SHOW_ADD_CARD, SHOW_ADD_MONEY, SHOW_DELETE_CARD, CARD_COPIED, CLOSE_FORMS, SAVE_FORM_DATA } from './types';
import { GenerateCard } from './helpers';

const defaultForms = {
    addCardActive: false,
    deleteCardActive: false,
    addMoneyActive: false,
    cardHistoryActive: false,
    cardCopiedActive: false,
    donateStatus: null,
}

const local = JSON.parse(localStorage.getItem('cards-app'));
if (local) {
    // local.forms = { ...defaultForms };
    local.donateFormData = null;
}

const freshApp = {
    cards: [],
    activeTab: 'account',
    forms: {...defaultForms},
    lastTargetCard: '',
    donateFormData: null,
};

const initial = local || freshApp;

const reducer = (state = initial, action) => {

    switch (action.type) {

        case ADD_CARD: {
            const newState = { ...state };
            const newCard = GenerateCard({ ...action.payload });
            const newCards = [...state.cards, newCard];
            newState.cards = newCards;

            return { ...newState };
        }

        case DELETE_CARD: {
            const newState = { ...state };
            const target = action.payload;
            const newCards = newState.cards.filter(el => el.number !== target);
            newState.lastTargetCard = action.payload;
            newState.cards = newCards;

            return { ...newState };
        }

        case ADD_MONEY: {
            const newState = { ...state };
            const target = newState.cards.findIndex(card => card.number === action.payload.number);
            const amount = action.payload.amount;
            const balance = parseInt(newState.cards[target].balance);
            const newBalance = (balance + parseInt(amount));

            newState.cards[target].balance = newBalance < 100000 ? newBalance : 100000;

            const balanceAction = {
                date: new Date().toLocaleDateString(),
                type: 'add',
                amount: amount,
                result: newState.cards[target].balance
            }

            newState.cards[target].history.push(balanceAction)

            return { ...newState }
        }

        case DONATE_MONEY: {
            const newState = { ...state };
            const cards = newState.cards;
            const input = action.payload;
            const amount = input.amount;

            let match = false;
            for (let card of cards) {
                match = input.number.replaceAll(/\s/g, '') === card.number.replaceAll(/\s/g, '');
                // match = match && input.name === card.name; //should be firstName
                // match = match && input.surname === card.surname; //should be lastName
                match = match && input.expDate === card.expDate;
                match = match && input.cvv === card.cvv;
                // match = match && card.balance >= input.amount;

                if (match) {
                    card.balance -= input.amount

                    const balanceAction = {
                        date: new Date().toLocaleDateString(),
                        type: 'donate',
                        amount: amount,
                        result: card.balance
                    }

                    card.history.push(balanceAction);

                    newState.forms.donateStatus = true;

                    return { ...newState }
                }
            }

            newState.forms.donateStatus = false;

            return { ...newState }
        }

        case SHOW_HISTORY: {
            const target = action.payload;
            const newState = { ...state };
            newState.forms.cardHistoryActive = true;
            newState.lastTargetCard = target;

            return { ...newState }
        }

        case SHOW_ADD_CARD: {
            const newState = { ...state };
            newState.forms.addCardActive = true;

            return { ...newState }
        }

        case SHOW_DELETE_CARD: {
            const newState = { ...state };
            newState.lastTargetCard = action.payload;
            newState.forms.deleteCardActive = true;

            return { ...newState }
        }

        case SHOW_ADD_MONEY: {
            const newState = { ...state };
            newState.lastTargetCard = action.payload;
            newState.forms.addMoneyActive = true;

            return { ...newState }
        }

        case CARD_COPIED: {
            const newState = { ...state };
            newState.forms.cardCopiedActive = true;
            navigator.clipboard.writeText(action.payload.split(' ').join(''));

            return { ...newState }
        }

        case SAVE_FORM_DATA: {
            const newState = { ...state };
            newState.donateFormData = action.payload;

            return { ...newState }
        }

        case CLOSE_FORMS: {
            const newState = { ...state };

            for (let key in newState.forms) {
                newState.forms[key] = defaultForms[key]
            }
            return { ...newState }
        }

        case SET_ACTIVE_TAB: {
            const target = action.payload;
            return {
                ...state,
                activeTab: target
            }
        }

        default:
            return state
    }
}

export { reducer };