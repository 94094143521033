import './styles.scss';
import { connect } from 'react-redux';

import { closeForms } from 'store/actions';

const CardHistory = ({ card, closeForms }) => (<div className='custom-form-outlet'>
    <div className='shade-background'>
    </div>
    <div className="my-form">
        <div className="form-heading">
            <h4 className='form-title'>Transactions by card</h4>
            <button className="close-button" onClick={closeForms}></button>
        </div>

        <ul className="operation-history">
            {card.history.map((el, index) => (
                <li className={'history-element'}
                 key={index}>
                    <span className="date">{el.date}</span>
                    <span className={"amount " + el.type}>{el.amount + '₴'}</span>
                    <span className="balance">{el.result + '₴'}</span>
                </li>)
            )}
            {
                card.history.length === 0 ? <><p className="form-content">Transaction history is empty</p></> : ''
            }

        </ul>
    </div>

</div>)


const mapStateToPrors = state => ({
    card: state.cards.find(card => card.number === state.lastTargetCard)
})

const mapDispatchToProps = {
    closeForms
}

export default connect(mapStateToPrors, mapDispatchToProps)(CardHistory);