import './styles.scss';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { connect } from 'react-redux';

import { addMoney, closeForms } from 'store/actions';
import { selectCard } from 'store/selectors';
import { addMoneyValidate } from '../../../shared/myValidation';

import MyErrorMessage from 'components/shared/MyErrorMessage';

const AddMoneyForm = ({ number, card, addMoney, closeForms }) => {
    const initualValues = {
        amount: 0,
    }

    const submitHandler = async (values) => {
        addMoney({ number, ...values });
        closeForms();
    }

    return (<div className='custom-form-outlet'>
        <div className='shade-background'>
        </div>
        <div className="my-form">
            <div className="form-heading">
                <h4 className='form-title'>Card replenishment</h4>
                <button className="close-button" onClick={closeForms}></button>
            </div>

            <Formik
                initialValues={initualValues}
                onSubmit={submitHandler}
                validationSchema={addMoneyValidate(card.balance)}
            >
                {form => (<Form className='add-new-card-form'>
                    <div className='controls-container'>
                        <label htmlFor="amount">Replenishment amount:</label>
                        <Field
                            as="input"
                            className="controls-input"
                            id="amount"
                            name="amount"
                            type='number'
                        />
                        <ErrorMessage name="amount" component={err => <MyErrorMessage text={err.children} />} />
                    </div>

                    <button type="submit" className='controls-button submit-button' disabled={!form.isValid || !form.dirty}>Add moneys</button>
                </Form>)}
            </Formik>
        </div>

    </div>)

}

const mapState = state => ({
    number: state.lastTargetCard,
    card: selectCard(state, state.lastTargetCard)
});

const mapDispatchToProps = {
    addMoney,
    closeForms
}

export default connect(mapState, mapDispatchToProps)(AddMoneyForm);