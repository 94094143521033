import './App.css';
import Kitties from './components/Kitties'

function App() {
  return (
    <Kitties/>
  );
}

export default App;
