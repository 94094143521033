import './styles.scss';
import DonateForm from './DonateForm';
import DonateText from './DonateText';


const DonateTab = () => {
    return <div className='donate-tab flex-container'>
        <DonateForm />
        <DonateText />
    </div>

}

export default DonateTab;