import './styles.scss';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { connect } from 'react-redux';
import { donateMoney, closeForms, saveFormData } from 'store/actions';

import MyErrorMessage from 'components/shared/MyErrorMessage';
import { donateValidate } from 'components/shared/myValidation';

const DonateForm = ({ donateMoney, donateStatus, closeForms, saveFormData, storedFormData }) => {

    const initualValues = storedFormData || {
        fullName: '',
        number: '',
        expDate: '',
        cvv: '',
        amount: '',
    }

    const submitHandler = async (values) => {
        [values.firstName, values.lastName] = values.fullName.split(' ');
        donateMoney(values);
        saveFormData(null);
        setTimeout(() => closeForms(), 5000);
    }

    return (<div className='donate-form-outlet flex-element'>
        {donateStatus === true ? <div className='donate-status-message donate-succes'>Thank you</div> :
            donateStatus === false ? <div className='donate-status-message donate-error'>Transaction failed, try again</div> :

                <Formik
                    initialValues={initualValues}
                    onSubmit={submitHandler}
                    validationSchema={donateValidate}
                    onReset={() => saveFormData(null)}
                >
                    {(form) => (<Form className='donate-form' onBlur={() => { saveFormData(form.values) }} >

                        <div className='controls-container'>
                            <label htmlFor="fullName">Cardholder's name</label>
                            <Field
                                as="input"
                                className='controls-input'
                                id="fullName"
                                name="fullName"
                                placeholder="Mark Zuckerberg"
                            />
                            <ErrorMessage name="fullName" component={err => <MyErrorMessage text={err.children} />} />
                        </div>

                        <div className='controls-container'>
                            <label htmlFor="number">Card number</label>
                            <Field
                                as="input"
                                className='controls-input'
                                id="number"
                                name="number"
                                placeholder="1234 5678 9012 3456"
                            />
                            <ErrorMessage name="number" component={err => <MyErrorMessage text={err.children} />} />
                        </div>

                        <div className='controls-container'>
                            <label htmlFor="expDate">Date of expiry</label>
                            <Field
                                as="input"
                                className='controls-input'
                                id="expDate"
                                name="expDate"
                                placeholder="05/35"
                            />
                            <ErrorMessage name="expDate" component={err => <MyErrorMessage text={err.children} />} />
                        </div>

                        <div className='controls-container'>
                            <label htmlFor="cvv">CVV</label>
                            <Field
                                as="input"
                                className='controls-input'
                                id="cvv"
                                name="cvv"
                                placeholder="•••"
                                type="password"
                            />
                            <ErrorMessage name="cvv" component={err => <MyErrorMessage text={err.children} />} />
                        </div>

                        <div className='controls-container'>
                            <label htmlFor="amount">Amount</label>
                            <Field
                                as="input"
                                className='controls-input'
                                id="amount"
                                name="amount"
                                placeholder="100"
                                type="number"
                            />
                            <ErrorMessage name="amount" component={err => <MyErrorMessage text={err.children} />} />
                        </div>

                        <div className='controls-container checkbox-container'>
                            <Field
                                as="input"
                                className='controls-checkbox'
                                id="legal"
                                name="legal"
                                type="checkbox"
                            />
                            <label htmlFor="legal">Agree with <a href="#"> terms of service</a></label>
                        </div>

                        <button type="submit" className='controls-button send-button'>Confirm payment</button>
                    </Form>
                    )}
                </Formik>}
    </div >)

}

const mapStateToProps = state => ({
    thankYouActive: state.forms.thankYouActive,
    donateStatus: state.forms.donateStatus,
    storedFormData: state.donateFormData
})

const mapDispatchToProps = {
    donateMoney,
    saveFormData,
    closeForms
}

export default connect(mapStateToProps, mapDispatchToProps)(DonateForm);