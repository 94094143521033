import './styles.scss';
import { Formik, Form } from 'formik';
import { connect } from 'react-redux';

import { deleteCard, closeForms } from 'store/actions';

const DeleteCardForm = ({ number, deleteCard, closeForms }) => {

    return (<div className='custom-form-outlet'>
        <div className='shade-background'>
        </div>
        <div className="my-form">
            <div className="form-heading">
                <h4 className='form-title'>Deleting a card</h4>
                <button className="close-button" onClick={closeForms}></button>
            </div>
            <p className='form-content'>Are you sure you want to delete this card?</p>

            <Formik
                initialValues={{
                    number: '',
                }}

                onSubmit={async () => {
                    deleteCard(number);
                    closeForms();
                }}
            >
                <Form className='delete-card-form'>
                    <button type="submit" className='controls-button submit-button'>Yes, delete card</button>
                </Form>
            </Formik>
        </div>

    </div>)

}

const mapState = state => ({
    number: state.lastTargetCard
});

const mapDispatchToProps = {
    deleteCard,
    closeForms
}

export default connect(mapState, mapDispatchToProps)(DeleteCardForm);