import './styles.scss';

import { connect } from 'react-redux';

import Card from './Card'
import AddCard from './AddCard'
import NewCardForm from 'components/Kitties/Forms/NewCardForm';
import DeleteCard from 'components/Kitties/Forms/DeleteCard';
import AddMoneyForm from 'components/Kitties/Forms/AddMoney';
import CardHistory from 'components/Kitties/Forms/CardHistory';
import Notification from 'components/Kitties/Forms/Notification'

import {
    showHistory,
    showAddCardForm,
    showAddMoneyForm
} from 'store/actions';


const Cards = ({ cards, addCardActive, addMoneyActive, deleteCardActive, cardHistoryActive, cardCopiedActive }) => {

    let content;

    content = <>
        {
            cards.map((card) => <Card
                key={card.number}
                {...card}
            />)
        }
        {
            cards.length < 4 ?
                < AddCard />
                :
                ''
        }

    </>

    return (<>
        <div className="account-tab">
            <div className="cards flex-container">
                {content}
            </div>
            {
                addCardActive && <NewCardForm />
            }
            {
                deleteCardActive && <DeleteCard />
            }
            {
                addMoneyActive && <AddMoneyForm />
            }
            {
                cardHistoryActive && <CardHistory />
            }
            {
                cardCopiedActive && <Notification>Card number copied to clipboard</Notification>
            }
        </div>
    </>
    )
}


const mapStateToPrors = state => ({
    cards: state.cards,
    addCardActive: state.forms.addCardActive,
    deleteCardActive: state.forms.deleteCardActive,
    addMoneyActive: state.forms.addMoneyActive,
    cardHistoryActive: state.forms.cardHistoryActive,
    cardCopiedActive: state.forms.cardCopiedActive,
})

const mapDispatchToProps = {
    showHistory,
    showAddCardForm,
    showAddMoneyForm
}

export default connect(mapStateToPrors, mapDispatchToProps)(Cards);